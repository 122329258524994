import React from 'react'
import styles from './hoursPerProject.module.scss'

const hoursPerProject = ({hours, project, isLast}) => {
    return (
        <h3 className="mb-2 mt-2">
            <span>{project}: </span>
            <span className={styles.pink}>{hours}h</span>
            {!isLast ? <span className={styles.dot}></span> : null}
        </h3>
    )
}

export default hoursPerProject
