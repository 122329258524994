import React from 'react'
import {createRoot} from 'react-dom/client'
import 'bootstrap/dist/css/bootstrap.min.css'
import './scss/internal/icons.scss'
import './scss/internal/typography.scss'
import './scss/internal/forms.scss'
import './scss/internal/grid.scss'
import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'

createRoot(document.getElementById('root')).render(<App/>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
