import React, {Component} from 'react'
import Tabs from '../../ui/Tabs/Tabs'
import TwoMonthsGrid from '../../components/Timesheet/twoMonthsGrid/TwoMonthsGrid'
import WeekGrid from '../../components/Timesheet/weekGrid/WeekGrid'
import {connect} from 'react-redux'
import {showModal, hideModal} from '../../store/actions/modals.actions'
import {loadHours, deleteHour} from '../../store/actions/timesheet.actions'
import {loadUserProjects} from '../../store/actions/projects.actions'

import '../../scss/internal/forms.scss'
import {toast} from 'react-toastify'

class Timesheet extends Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: null,
        }
    }

    componentDidMount() {
        this.onTabChange('Month')
    }

    loadHoursAndUserProjects = () => {
        this.props.loadHours()
        this.props.loadUserProjects()
    }

    onTabChange = tab => {
        this.setState({activeTab: tab})
        this.loadHoursAndUserProjects()
    }

    handleHourDelete = hour => {
        this.props.showModal(
            {
                open: true,
                message: (
                    <>
                        <span>Are You sure You want to delete this record?</span>
                        <br />
                        <span>
                            Deleting task <strong>{hour.task}</strong> from project <strong>{hour.project.name}</strong>{' '}
                            with total hours: <strong>{hour.value}</strong>
                        </span>
                    </>
                ),
                confirm: () => this.deleteHour(hour.id),
            },
            'confirm',
        )
    }

    deleteHour = hourId => {
        this.props.showModal({open: true, message: 'Deleting hour'}, 'spinner')
        this.props.deleteHour(hourId, this.onSuccessDelete, this.onErrorDelete)
    }

    onSuccessDelete = () => {
        this.props.hideModal()
        toast.success('Hour deleted')
    }

    onErrorDelete = () => {
        this.props.hideModal()
        toast.error('Hour not deleted')
    }

    render() {
        return (
            <div className="page-margin my-4">
                <h1>
                    Timesheet
                </h1>
                <Tabs
                    activeTab={this.state.activeTab}
                    setActiveTab={this.onTabChange}
                    isLoading={!this.props.projectsLoaded || !this.props.hoursLoaded}
                    tabs={{
                        Month: {
                            children: (
                                <TwoMonthsGrid
                                    handleHourDelete={hour => this.handleHourDelete(hour)}
                                    projects={this.props.projects}
                                    hours={this.props.hours ?? []}
                                />
                            ),
                        },
                        Week: {
                            children: (
                                <WeekGrid
                                    handleHourDelete={this.handleHourDelete}
                                    projects={this.props.projects}
                                    hours={this.props.hours ?? []}
                                />
                            ),
                        },
                    }}
                />
            </div>
        )
    }
}

const mapStateToProps = state => ({
    hoursLoaded: state.timesheet.hoursLoaded,
    hours: state.timesheet.hours,
    projects: state.projects.projects,
    projectsLoaded: state.projects.projectsLoaded,
})

const mapDispatchToProps = dispatch => {
    return {
        loadHours: () => dispatch(loadHours()),
        loadUserProjects: () => dispatch(loadUserProjects(false)),
        deleteHour: (hourId, onSuccess, onError) => dispatch(deleteHour(hourId, onSuccess, onError)),
        showModal: (modalProps, modalType) => dispatch(showModal(modalProps, modalType)),
        hideModal: () => dispatch(hideModal()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Timesheet)
