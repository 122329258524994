import React from 'react'
import './App.scss'
import historyReducer from './store/reducers/history.reducer'
import authReducer from './store/reducers/authentication.reducer'
import projectsReducer from './store/reducers/projects.reducer'
import employeesReducer from './store/reducers/employees.reducer'
import {Provider} from 'react-redux'
import {createStore, combineReducers, applyMiddleware, compose} from 'redux'
import {thunk} from 'redux-thunk'
import AppRoutes from './AppRoutes'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import typesReducer from './store/reducers/types.reducer'
import modalsReducer from './store/reducers/modals.reducer'
import timesheetReducer from './store/reducers/timesheet.reducer'
import reportReducer from './store/reducers/report.reducer'
import vacationsReducer from './store/reducers/vacation.reducer'

const rooteReducer = combineReducers({
    history: historyReducer,
    auth: authReducer,
    projects: projectsReducer,
    employees: employeesReducer,
    types: typesReducer,
    modals: modalsReducer,
    timesheet: timesheetReducer,
    report: reportReducer,
    vacations: vacationsReducer,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export const store = createStore(rooteReducer, composeEnhancers(applyMiddleware(thunk)))

function App() {
    return (
        <Provider store={store}>
            <ToastContainer autoClose={2000}/>
            <AppRoutes />
        </Provider>
    )
}

export default App
