import React from 'react'
import HoursPerProject from '../HoursPerProject/HoursPerProject'
import styles from './hoursSummary.module.scss'

const hoursSummary = ({hours}) => {
    const hoursPerProjectObj = hours.reduce((acc, hour) => {
        if (acc[hour?.project?.name]) {
            acc[hour?.project?.name] += hour?.value
        } else {
            acc[hour?.project?.name] = hour?.value
        }

        return acc
    }, {})

    const hoursPerProject = Object.entries(hoursPerProjectObj).map(([project, hours], index) => ({
        project,
        hours,
        isLast: index === Object.keys(hoursPerProjectObj).length - 1,
    }))

    return (
        <div className={styles.container}>
            {hoursPerProject.map(hoursPerProject => (
                <HoursPerProject key={hoursPerProject.project} {...hoursPerProject} />
            ))}
        </div>
    )
}

export default hoursSummary
